import React from 'react';
import Layout from "../../../components/layout-black"
import { StaticImage } from "gatsby-plugin-image"
import Fancybox from "../../../components/fancybox.js";
import BreadcrumbsInterier from "../../../components/breadcrumbs-interier.js";
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { IncontentYouToobe } from "../../../subitems/incontent-youToobe"
import Seo from "../../../components/seo"
import { CalculatorLink } from "../../../components/calculator/calculatorLink"
import { Compliment } from "../../../subitems/compliment/compliment"
import { PartnersDzerzhinskogo } from "../../../subitems/partners/partnersDzerzhinskogo"

const dzerzhinskogo = () => {
  return (
    <Layout>
      <Seo  title={"Дизайн интерьера квартиры для семьи с двумя детьми 90 м2 | I-PROJECT"} description={"Фотографии интерьера современной квартиры для семьи в Минске."}
            location={"https://i-project.by/portfolio/interier/apart-family"}
            image={"https://i-project.by/portfolio_newsite/dzerzhinskogo/01_dzerzhinskogo.webp"}/>
      <Fancybox>
        <div class="wrapper">
          <div class="project">
            <BreadcrumbsInterier />
            <h1 class="project_title">Интерьер квартиры в&nbsp;Минске для семьи с&nbsp;двумя детьми, 90м<sup>2</sup></h1>
          </div>
          <div class="project_main_photo">
            <a
              href="https://i-project.by/portfolio_newsite/dzerzhinskogo/01_dzerzhinskogo.webp"
              data-fancybox="gallery"
            >
              <StaticImage src="../../../images/portfolio/dzerzhinskogo/01_dzerzhinskogo.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="content_body">
          <div class="content_body_absolute">
            <div class="left_sidebar">
              <ul>
                <li><AnchorLink to="/portfolio/interier/apart-family/#anchor_1" title="Описание проекта" /></li>
                <li><AnchorLink to="/portfolio/interier/apart-family/#anchor_2" title="Планировка квартиры" /></li>
                <li><AnchorLink to="/portfolio/interier/apart-family/#anchor_3" title="Гостиная" /></li>
                <li><AnchorLink to="/portfolio/interier/apart-family/#anchor_4" title="Кухня-столовая" /></li>
                <li><AnchorLink to="/portfolio/interier/apart-family/#anchor_5" title="Спальня" /></li>
                <li><AnchorLink to="/portfolio/interier/apart-family/#anchor_6" title="Рабочие места" /></li>
              </ul>
            </div>
          </div>
          <div class="wrapper">
            <div class="right_content">
              <div class="project_info">
                <p class="project_info_p">Год: <span>2023</span></p>
                <p class="project_info_p">Локация: <span>Минск</span></p>
                <p class="project_info_p">Архитектор: <span>Александр Игнатьев</span></p>
              </div>
              <div class="content_task_block">
                <a
                  href="https://i-project.by/portfolio_newsite/dzerzhinskogo/03_dzerzhinskogo.webp"
                  data-fancybox="gallery"
                >
                  <StaticImage src="../../../images/portfolio/dzerzhinskogo/03_dzerzhinskogo.jpg" alt="" />
                </a>
                <a
                  href="https://i-project.by/portfolio_newsite/dzerzhinskogo/05_dzerzhinskogo.webp"
                  data-fancybox="gallery"
                >
                  <StaticImage src="../../../images/portfolio/dzerzhinskogo/05_dzerzhinskogo.jpg" alt="" />
                </a>
                <a
                  href="https://i-project.by/portfolio_newsite/dzerzhinskogo/01_dzerzhinskogo.webp"
                  data-fancybox="gallery"
                >
                  <StaticImage src="../../../images/portfolio/dzerzhinskogo/01_dzerzhinskogo.jpg" alt="" />
                </a>
                <div class="content_task">
                  <span class="content_task_span">Описание</span>
                  <p class="content_task_p">Городская квартира для семьи из&nbsp;четырех человек&nbsp;&mdash; супругов и&nbsp;двух дочерей. Заказчики хотели получить практичный интерьер для повседневной жизни, который в&nbsp;то&nbsp;же время будет стильным и&nbsp;современным. Несмотря на&nbsp;относительно небольшую площадь и&nbsp;сложную исходную планировку квартиры, итоговое пространство получилось функциональным и&nbsp;гармоничным.</p>
                </div>
              </div>
              <div class="content_text">
                <h2 class="content_h2" id="anchor_2">Планировка квартиры</h2>
                <p class="content_p">Перед нами стояла задача&nbsp;&mdash; сделать планировочное решение максимально эргономичным. В&nbsp;квартире две спальни&nbsp;&mdash; взрослая и&nbsp;детская, а&nbsp;также два санузла. Для каждого члена семьи предусмотрены отдельные рабочие зоны, по&nbsp;всей квартире оборудованы системы хранения: шкафы и&nbsp;стеллажи.</p>
              </div>
              <div class="content_images">
                <div class="content_images_two">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/15_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/17_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/16_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/18_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <IncontentYouToobe
                param={"start=0"}
                title={"Обзор интерьера в квартире для семьи в Минске"}
                embed={'nLeSdhbcI_A'} 
              />
              <div class="content_text">
                <h2 class="content_h2" id="anchor_3">Гостиная</h2>
                <p class="content_p">Цветовая гамма насыщенная и&nbsp;глубокая. Мы&nbsp;взяли за&nbsp;основу характерные для скандинавского стиля белые стены и&nbsp;деревянные поверхности (паркетная доска дуба на&nbsp;полу, шпон ореха на&nbsp;стенах) и&nbsp;дополнили их&nbsp;оттенками серого, кораллового, бирюзового и&nbsp;небесно-голубого. Для контраста добавили глубокий синий, рыжий, кирпичный и&nbsp;черный.</p>
              </div>
              <div class="content_images">
                <div class="content_images_one">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/02_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/02_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <div class="content_text">
                <p class="content_p">Разделительным элементом между гостиной и&nbsp;прихожей стал шкаф-стеллаж. Со&nbsp;стороны прихожей в&nbsp;нем можно хранить одежду, со&nbsp;стороны гостиной&nbsp;&mdash; книги и&nbsp;предметы декора.</p>
              </div>
              <div class="content_images">
                <div class="content_images_two">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/03_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/03_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/04_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/04_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
                <div class="content_images_two">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/05_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/05_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/06_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/06_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <CalculatorLink />
              <div class="content_text">
                <h2 class="content_h2" id="anchor_4">Кухня-столовая</h2>
                <p class="content_p">Заказчики попросили сохранить разграничение кухни и&nbsp;гостиной, чтобы была возможность пользоваться каждым помещением по&nbsp;отдельности. Поэтому мы&nbsp;разделили комнаты глухой стеной с&nbsp;раздвижной перегородкой&nbsp;&mdash; и&nbsp;организовали открытую связь через коридор. Пространство кухни-столовой увеличили за&nbsp;счет присоединения утепленной лоджии.</p>
              </div>
              <div class="content_images">
                <div class="content_images_three">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/07_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/07_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/08_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/08_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/09_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/09_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <div class="content_text">
                <p class="content_p">Ключевой элемент интерьера кухни&nbsp;&mdash; авторский гарнитур, в&nbsp;отделке фасадов которого используются сложная комбинация цветов и&nbsp;фактур: фрезерованный шпон дуба, крашенный мдф, тонированное стекло. Обеденная группа акцентирована с&nbsp;помощью стульев MIDJ Guapa. Они невероятно удобные и&nbsp;выразительные, поэтому мы&nbsp;часто используем их&nbsp;в&nbsp;наших проектах.</p>
                <h2 class="content_h2" id="anchor_5">Спальня</h2>
                <p class="content_p">В&nbsp;спальне нет ничего лишнего&nbsp;&mdash; кровать Ditre и&nbsp;плательный шкаф, совмещенный с&nbsp;ТВ-зоной. Из&nbsp;спальни предусмотрен прямой доступ к&nbsp;санузлу.</p>
                <p class="content_p">Стены спальни, детской, гостиной и&nbsp;коридора украшает живопись Дениса Синявского.</p>
              </div>
              <div class="content_images">
                <div class="content_images_two_left_narrow">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/10_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/10_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/11_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/11_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <div class="content_text">
                <h2 class="content_h2" id="anchor_6">Рабочие места</h2>
                <p class="content_p">В&nbsp;квартире три рабочие зоны&nbsp;&mdash; одна на&nbsp;лоджии рядом со&nbsp;спальней взрослых, еще две&nbsp;&mdash; в&nbsp;детской комнате.</p>
              </div>
              <div class="content_images">
                <div class="content_images_two">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/12_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/12_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/13_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/13_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <div class="content_text">
                <p class="content_p">При оформлении пространства мы&nbsp;использовали корпусную мебель Domani, освещение Centrsvet, шторы и&nbsp;текстиль от&nbsp;Марины Игнатьевой, авторские керамические работы Лизы Пулко.</p>
              </div>
              <div class="content_images">
                <div class="content_images_three">
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/14_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/14_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/15_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/15_dzerzhinskogo.jpg" alt="" />
                  </a>
                  <a
                    href="https://i-project.by/portfolio_newsite/dzerzhinskogo/16_dzerzhinskogo.webp"
                    data-fancybox="gallery"
                  >
                    <StaticImage src="../../../images/portfolio/dzerzhinskogo/16_dzerzhinskogo.jpg" alt="" />
                  </a>
                </div>
              </div>
              <Compliment />
              <PartnersDzerzhinskogo />
            </div>     
          </div>
        </div>
      </Fancybox>
    </Layout>
  )
};

export default dzerzhinskogo;
